import React from 'react'
import PropTypes from 'prop-types'
// import { graphql } from 'gatsby'
import url from 'url'

import config from '../../../utils/siteConfig'
import ArticleMeta from './ArticleMeta'
import WebsiteMeta from './WebsiteMeta'
import AuthorMeta from './AuthorMeta'

/**
* MetaData will generate all relevant meta data information incl.
* JSON-LD (schema.org), Open Graph (Facebook) and Twitter properties.
*
*/
const MetaData = ({
    data,
    // settings,
    title,
    description,
    image,
    location,
}) => {
    const canonical = url.resolve(config.siteUrl, location.pathname)
    const { post, tag, author, page } = data

    if (post) {
        return (
            <ArticleMeta
                data={post}
                canonical={canonical}
            />
        )
    } else if (tag) {
        return (
            <WebsiteMeta
                data={tag}
                canonical={canonical}
                type="Series"
            />
        )
    } else if (author) {
        return (
            <AuthorMeta
                data={author}
                canonical={canonical}
            />
        )
    } else if (page) {
        return (
            <WebsiteMeta
                data={page}
                canonical={canonical}
                type="WebSite"
            />
        )
    } else {
        title = title ?? config.siteTitleMeta
        description = description ?? config.siteDescriptionMeta
        image = image ?? config.cover_image ?? null

        image = image ? url.resolve(config.siteUrl, image) : null

        return (
            <WebsiteMeta
                data={{}}
                canonical={canonical}
                title={title}
                description={description}
                image={image}
                type="WebSite"
            />
        )
    }
}

MetaData.defaultProps = {
    data: {},
}

MetaData.propTypes = {
    data: PropTypes.shape({
        post: PropTypes.object,
        tag: PropTypes.object,
        author: PropTypes.object,
        page: PropTypes.object,
    }).isRequired,
    // settings: PropTypes.shape,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
}

export default MetaData

import React from 'react'
import Helmet from "react-helmet"
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import url from 'url'
import ImageMeta from './ImageMeta'
import config from '../../../utils/siteConfig'

const ArticleMeta = ({ data, canonical }) => {
    const post = data
    // console.log(post)
    const author = post.frontmatter.author
    const authorFullname = `${author.firstName} ${author.lastName} `
    const tags = post.frontmatter.tags ?? []
    let publicTags = tags.filter(tag => tag.visible === true) ?? []
    publicTags = publicTags.map(tag => tag.title)

    // _.map(tagsHelper(post, { visibility: `public`, fn: tag => tag }), `name`)
    const primaryTag = publicTags[0] || ``
    const shareImage = post.frontmatter.featureImage.src ? post.frontmatter.featureImage.src.publicURL : config.coverImage
    const publisherLogo = (config.logo || config.siteIcon) ? url.resolve(config.siteUrl, (config.logo || config.siteIcon)) : null

    const jsonLd = {
        "@context": `https://schema.org/`,
        "@type": `Article`,
        author: {
            "@type": `Person`,
            name: authorFullname,
            image: author.image ? author.image.publicURL : undefined,
            sameAs: [config.siteUrl, `https://twitter.com/${config.twitter.replace(/^@/, ``)}/`],
        },
        keywords: publicTags.length ? publicTags.join(`, `) : undefined,
        headline: post.frontmatter.meta.title || post.frontmatter.title,
        url: canonical,
        datePublished: post.frontmatter.published_at,
        dateModified: post.frontmatter.updated_at,
        image: shareImage ? {
            "@type": `ImageObject`,
            url: shareImage,
            width: config.shareImageWidth,
            height: config.shareImageHeight,
        } : undefined,
        publisher: {
            "@type": `Organization`,
            name: config.siteTitleMeta,
            logo: {
                "@type": `ImageObject`,
                url: publisherLogo,
                width: 60,
                height: 60,
            },
        },
        description: post.frontmatter.meta.description || post.frontmatter.excerpt,
        mainEntityOfPage: {
            "@type": `WebPage`,
            "@id": config.siteUrl,
        },
    }

    return (
        <>
            <Helmet>
                <title>{post.frontmatter.title || post.frontmatter.meta.title}</title>
                <meta name="description" content={post.frontmatter.meta.description || post.frontmatter.excerpt} />
                <link rel="canonical" href={canonical} />

                <meta property="og:site_name" content={config.siteTitleMeta} />
                <meta property="og:type" content="article" />
                <meta property="og:title"
                    content={
                        post.frontmatter.social.og.title ||
                        post.frontmatter.meta.title ||
                        post.frontmatter.title
                    }
                />
                <meta property="og:description"
                    content={
                        post.frontmatter.social.og.description ||
                        post.frontmatter.excerpt ||
                        post.frontmatter.meta.description
                    }
                />
                <meta property="og:url" content={canonical} />
                <meta property="article:published_time" content={post.frontmatter.published_at} />
                <meta property="article:modified_time" content={post.frontmatter.updated_at} />
                {publicTags.map((keyword, i) => (<meta property="article:tag" content={keyword} key={i} />))}
                {author.facebookUrl && <meta property="article:author" content={author.facebookUrl} />}

                <meta name="twitter:title"
                    content={
                        post.frontmatter.social.twitter.title ||
                        post.frontmatter.meta.title ||
                        post.frontmatter.title
                    }
                />
                <meta name="twitter:description"
                    content={
                        post.frontmatter.social.twitter.description ||
                        post.frontmatter.excerpt ||
                        post.frontmatter.meta.description
                    }
                />
                <meta name="twitter:url" content={canonical} />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content={authorFullname} />
                {primaryTag && <meta name="twitter:label2" content="Filed under" />}
                {primaryTag && <meta name="twitter:data2" content={primaryTag} />}

                {config.twitter && <meta name="twitter:site" content={`https://twitter.com/${config.twitter.replace(/^@/, ``)}/`} />}
                {config.twitter && <meta name="twitter:creator" content={config.twitter} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
            </Helmet>
            <ImageMeta
                image={post.frontmatter.featureImage.src? post.frontmatter.featureImage.src.publicURL: undefined}
                twitterImage={post.frontmatter.social.twitter.src ? post.frontmatter.social.twitter.src.publicURL : undefined}
                ogImage={post.frontmatter.social.og.src? post.frontmatter.social.og.src.publicURL : undefined}
             />
        </>
    )
}

ArticleMeta.propTypes = {
    data: PropTypes.shape({
        frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
            excerpt: PropTypes.string,
            featured: PropTypes.bool,
            featureImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                src: PropTypes.object,
            }),
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                })
            ),
            published_at: PropTypes.string.isRequired,
            excerpt: PropTypes.string.isRequired,
            author: PropTypes.shape({
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string,
                fullName: PropTypes.string,
                image: PropTypes.object,
            }).isRequired,

            meta: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
            }).isRequired,

            social: PropTypes.shape({
                twitter: PropTypes.shape({
                    title: PropTypes.string,
                    description: PropTypes.string,
                    src: PropTypes.object,
                }),
                og: PropTypes.shape({
                    title: PropTypes.string,
                    description: PropTypes.string,
                    src: PropTypes.object,
                }),
            }).isRequired,
        }).isRequired,
    }).isRequired,
    canonical: PropTypes.string.isRequired,
}

export default ArticleMeta

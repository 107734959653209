import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import config from '../../../utils/siteConfig'

const ImageMeta = ({ image, twitterImage, ogImage }) => {
    if (!image && !twitterImage && !ogImage) {
        return null
    }

    let twitterImageURL = twitterImage || image || ogImage
    let ogImageURL = ogImage || image || twitterImage

    // Create full url if only path provided
    if (!twitterImageURL.includes(`https`))
        twitterImageURL = config.siteUrl + twitterImageURL

    if (!ogImageURL.includes(`https`))
        ogImageURL = config.siteUrl + ogImageURL

    return (
        <Helmet>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={twitterImageURL} />
            <meta property="og:image" content={ogImageURL} />
            <meta property="og:image:width" content={config.shareImageWidth} />
            <meta property="og:image:height" content={config.shareImageHeight} />
        </Helmet >
    )
}

ImageMeta.propTypes = {
    image: PropTypes.string,
    twitterImage: PropTypes.string,
    ogImage: PropTypes.string,
}

export default ImageMeta

import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
// import _ from 'lodash'
// import { StaticQuery, graphql } from 'gatsby'

import ImageMeta from './ImageMeta'
import config from '../../../utils/siteConfig'

const AuthorMeta = ({ data, canonical }) => {

    const author = data
    const authorFullname = `${author.firstName} ${author.lastName} `
    const shareImage = author.image.publicURL || config.coverImage
    const title = `${author.firstName} - ${config.siteTitleMeta}`
    const description = author.bio || config.siteDescriptionMeta

    const jsonLd = {
        "@context": `https://schema.org/`,
        "@type": `Person`,
        name: authorFullname,
        sameAs: [config.siteUrl, `https://twitter.com/${config.twitter.replace(/^@/, ``)}/`],
        url: canonical,
        image: shareImage ? {
            "@type": `ImageObject`,
            url: shareImage,
            width: config.shareImageWidth,
            height: config.shareImageHeight,
        } : undefined,
        mainEntityOfPage: {
            "@type": `WebPage`,
            "@id": config.siteUrl,
        },
        description,
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonical} />
                <meta property="og:site_name" content={config.title} />
                <meta property="og:type" content="profile" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={canonical} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:url" content={canonical} />
                {config.twitter && <meta name="twitter:site" content={`https://twitter.com/${config.twitter.replace(/^@/, ``)}/`} />}
                {config.twitter && <meta name="twitter:creator" content={config.twitter} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
            </Helmet>
            <ImageMeta image={shareImage} />
        </>
    )
}

AuthorMeta.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        bio: PropTypes.string,
        profile_image: PropTypes.string,
        website: PropTypes.string,
        twitter: PropTypes.string,
        facebook: PropTypes.string,
    }).isRequired,
    canonical: PropTypes.string.isRequired,
}

export default AuthorMeta

import React from 'react'
import Helmet from "react-helmet"
import PropTypes from 'prop-types'
// import _ from 'lodash'
// import { StaticQuery, graphql } from 'gatsby'
import url from 'url'

import ImageMeta from './ImageMeta'
import config from '../../../utils/siteConfig'

const WebsiteMeta = ({ data, canonical, title, description, image, type }) => {

    const publisherLogo = url.resolve(config.siteUrl, (config.logo || config.siteIcon))
    let shareImage = image || data.feature_image || data.featureImage?.src.publicURL || config.coverImage

    shareImage = shareImage ? url.resolve(config.siteUrl, shareImage) : null

    description = description || data.meta?.description || data.meta_description || data.description || config.siteDescriptionMeta
    // title = `${title ?? data.meta?.title ?? data.meta_title ?? data.name ?? data.title} - ${config.siteTitleMeta}`
    title = `${title || data.meta?.title || data.meta_title || data.name || data.title}`

    if (title !== config.siteTitleMeta)
        title =  `${title} - ${config.siteTitleMeta}`

    const jsonLd = {
        "@context": `https://schema.org/`,
        "@type": type,
        url: canonical,
        image: shareImage ?
            {
                "@type": `ImageObject`,
                url: shareImage,
                width: config.shareImageWidth,
                height: config.shareImageHeight,
            } : undefined,
        publisher: {
            "@type": `Organization`,
            name: config.siteTitleMeta,
            logo: {
                "@type": `ImageObject`,
                url: publisherLogo,
                width: 60,
                height: 60,
            },
        },
        mainEntityOfPage: {
            "@type": `WebPage`,
            "@id": config.siteUrl,
        },
        description,
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <link rel="canonical" href={canonical} />
                <meta property="og:site_name" content={config.siteTitleMeta} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={canonical} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:url" content={canonical} />
                {config.twitter && <meta name="twitter:site" content={`https://twitter.com/${config.twitter.replace(/^@/, ``)}/`} />}
                {config.twitter && <meta name="twitter:creator" content={config.twitter} />}
                <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
            </Helmet>
            <ImageMeta image={shareImage} />
        </>
    )
}

WebsiteMeta.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        meta: PropTypes.object,
        meta_title: PropTypes.string,
        meta_description: PropTypes.string,
        name: PropTypes.string,
        feature_image: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    canonical: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    type: PropTypes.oneOf([`WebSite`, `Series`]).isRequired,
}

export default WebsiteMeta
